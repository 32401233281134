















import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "DataLoading",
  data() {
    return {
      animation: require("@/assets/illustrations/analyzing.json")
    };
  },
  created() {
    this.animation = JSON.stringify(this.animation);
  },
  props: {
    message: {
      type: String as PropType<TranslateResult>,
      required: true
    }
  }
});
