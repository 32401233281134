import { ExtractedSkillsWithGroup } from "@/interfaces/responses/extracted_skills_with_group";
import { TopjobsWithSectors } from "@/interfaces/responses/topjobs_with_sectors";
import { Institutes } from "@/interfaces/data_objects/institutes";
import {
  GlobalInstituteData,
  NationalInstituteData
} from "@/interfaces/modules/institute/national_global_comparison_choice";
import { InstitutesCompare } from "@/interfaces/responses/institutes_compare";
import { UpskillCourses } from "@/interfaces/data_objects/upskill_courses";

export interface InstituteState {
  institute_loading: boolean;
  degree_gap_loading: boolean;
  global_degree_loading: boolean;
  national_degree_loading: boolean;
  national_degree_skills: ExtractedSkillsWithGroup.Root | null;
  global_degree_skills: ExtractedSkillsWithGroup.Root | null;
  national_degree_topjobs: TopjobsWithSectors.Root | null;
  institutes: Institutes[];
  selected_national_institute_data: NationalInstituteData;
  selected_global_institute_data: GlobalInstituteData;
  comparison_other_skills_loading: boolean;
  comparison_other_skills: InstitutesCompare.Root | null;
  upskill_courses_loading: boolean;
  upskill_courses: UpskillCourses.UpskillCoursesRoot | null;
  upskill_course_payload: UpskillCoursesPayload | null;
}

export interface DegreeJobComparisonPayload {
  ref_institute_id: number;
  ref_program_id: number;
}

export interface DegreeTopJobsPayload {
  response_id?: string;
  total_count: number;
  type: string;
  ref_institute_id?: number;
  ref_program_id?: number;
}

export interface NationalGlobalComparisonPayload {
  ref_institute_id: number;
  ref_program_id?: number;
  compare_institute_id: number;
  compare_program_id?: number;
  ref_skills?: ExtractedSkillsWithGroup.Skills[];
  compare_skills?: ExtractedSkillsWithGroup.Skills[];
  type: string;
}

export interface UpskillCoursesPayload {
  gap_list: string[];
  job_id?: number;
  upload_response_id?: string;
  compare_upload_response_id?: string;
  ref_institute_id?: number;
  ref_program_id?: number;
  compare_institute_id?: number;
  compare_program_id?: number;
  type: UpskillCoursesPayloadTypes;
}

export enum UpskillCoursesPayloadTypes {
  JOB = "job",
  COURSE = "course"
}
